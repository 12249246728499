.channel-item {
  display: flex;
  align-items: center;
  padding: 12px;

  .avatar {
    width: 48px;
    min-width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  .info {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-size: 16px;
    }

    .update-time {
      font-size: 13px;
      color: #999;
      margin-top: 4px;
    }
  }
}
