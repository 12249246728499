.video-item {
  padding: 12px 12px;

  .thumbnail {
    border-radius: 5px;
    box-shadow: 0 0 5px #ccc;
    position: relative;
    overflow: hidden;

    .image {
      width: 100%;
      height: 56%vw;
    }

    .duration {
      position: absolute;
      bottom: 10px;
      right: 10px;
      font-size: 12px;
      color: #fff;
      background: rgba($color: #444, $alpha: 0.8);
      padding: 3px 5px;
      border-radius: 5px;
    }

    .progress-bar {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
