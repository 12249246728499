.news-item {
  background-color: #fff;
  padding: 20px 12px;
  border-bottom: 1px solid #eee;

  .title {
    font-size: 14px;
    font-weight: 600;
  }

  .time {
    font-size: 12px;
    color: #777;
    margin-top: 6px;
  }
}
