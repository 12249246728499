.news-detail-page {
  padding: 20px 12px;
  overflow-x: hidden;

  .title {
    font-size: 18px;
    font-weight: 600;
  }

  .time {
    font-size: 13px;
    color: #777;
    margin-top: 10px;
  }

  .content {
    font-size: 16px;
    line-height: 1.4;

    img {
      width: 100%;
    }
  }
}
