.comment-item {
  padding: 12px 15px;
  display: flex;
  overflow: hidden;

  .profile-image {
    width: 42px;
    height: 42px;
    border-radius: 50px;
    min-width: 42px;
  }

  .comment-body {
    margin-left: 12px;
    flex: auto;

    .user-info {
      font-size: 14px;
      font-weight: 600;
    }

    .content {
      margin-top: 12px;
      font-size: 14px;
      color: #000;
    }

    .bottom-content {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      font-size: 12px;
      color: #777;
    }
  }
}
