.sticky {
  position: sticky;
  top: 0;
  z-index: 99;

  .player {
    z-index: 99;
  }
}

.detail-info {
  display: block;
  padding: 12px 12px 20px 12px;
  background-color: #ffffff;
  z-index: 99;
}

.comment-info {
  margin-top: 20px;

  .title {
    padding: 12px;
  }

  .comment-list {
    background-color: #ffffff;
  }
}
