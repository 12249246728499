.main {
  .nav-bar {
    z-index: 99;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #ffffff;
  }

  .content {
    margin: 0;
  }
}
