.app {
  .app-body {
    margin-bottom: 50px;
  }

  .app-tabbar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    box-shadow: 1px 0 5px #ddd;
  }
}
