.video-info {
  display: flex;
  margin-top: 8px;

  .avatar {
    min-width: 46px;
    height: 46px;
    border-radius: 50%;
    box-shadow: 0 0 5px #ccc;
  }

  .title-info {
    margin-left: 8px;
    flex: auto;

    .video-title {
      font-size: 13px;
      color: #000;
      letter-spacing: 0;
      display: -webkit-box;
    }

    .short {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .channel-title {
      font-size: 12px;
      color: #777;
      margin-top: 3px;
    }

    .video-statistic {
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      color: #777;
    }
  }
}
